import React from 'react'
import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "gatsby";
import ApiImage from '../assets/images/api_illustration.png';

const Docs = () => {
    return (
        <div className={'bg-dark py-5 mb-5'}>
            <Container className={'pt-3 text-secondary'}>
                <Row className={'align-items-center g-lg-5 py-5'}>
                    <Col xl={6} lg={7} md={6} className={'text-center text-md-start mt-0'}>
                        <h2 className="text-orange fw-normal mb-4 mt-md-5 pt-md-5 pt-sm-0 mt-sm-0">
                            Meet our APIs
                        </h2>

                        <p className="mb-4 text-white-50">
                            Our powerful, flexible, and hosted route optimization API require
                            no knowledge of optimization techniques. High-quality results are returned -
                            even at scale.
                        </p>

                        <Link to={'/docs'} className={'text-orange text-decoration-none pt-5'}>
                            <p className={'docs-link d-inline'}>
                                View Documentation
                            </p>

                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 className="icon ms-2">
                                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
                                      fill="currentColor"></path>
                            </svg>
                        </Link>
                    </Col>
                    <Col xl={5} lg={5} md={6} className={'mx-auto pt-5 pt-lg-4 px-sm-4 ps-md-4 pe-md-0 ps-xl-5 mt-sm-5 mt-md-0'}>
                        <Image
                            src={ApiImage}
                            className={'d-block mx-lg-auto img-fluid'}
                            alt={'API illustration'}
                            loading={'lazy'}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Docs