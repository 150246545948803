import React, {useState} from "react";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {navigate} from "gatsby";
import HeroImage from '../assets/images/hero_illustration.png'

const Hero = () => {

    const [email, setEmail] = useState('');

    return (
        <div className={'bg-dark'}>
            <Container className={'pt-3 text-secondary'}>
                <Row className={'align-items-center g-lg-5 py-5'}>
                    <Col lg={4} className={'text-center text-lg-start mt-0'}>
                        <h2 className="text-orange fw-normal mb-4 mt-3 hero-title">
                            Meet our powerful<br/>
                            route optimization engine
                        </h2>

                        <p className="mb-4 text-white-50">
                            Increase your revenue by improving your routes for greater efficiency and cost
                            effectiveness. Maximize the delivered orders, taking into account a variety of constraints.
                        </p>

                        <div className="d-grid gap-2 d-sm-flex d-lg-grid d-xl-flex justify-content-lg-start justify-content-sm-center">
                            <input
                                type={"text"}
                                className="email-input-hero form-control font-14"
                                placeholder="Enter your email"
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Button
                                onClick={() => {
                                    navigate('/contact', {
                                        state: {email}
                                    })
                                }}
                                className={'schedule-btn-hero btn-orange px-4 me-md-2 font-14'}
                                style={{minWidth: '159px'}}
                            >
                                Schedule a demo
                            </Button>
                        </div>
                    </Col>
                    <Col lg={8} className={'mx-auto pt-5 pt-lg-0'}>
                        <Image
                            src={HeroImage}
                            className={'d-block mx-lg-auto img-fluid'}
                            alt={'Greengard route optimization'}
                            loading={'lazy'}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero