import React from "react";
import Hero from "../components/hero";
import Docs from "../components/docs";
import HowItWorks from "../components/howItWorks";
import Layout from "../components/layout";
import Admin from "../components/admin";
import SEO from "../components/seo";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title={'Greengard'}/>
            <Hero/>
            <HowItWorks/>
            <Admin/>
            <Docs/>
        </Layout>
    );
};

export default IndexPage
