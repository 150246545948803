import React from "react";
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {ImMagicWand} from "react-icons/im";
import {FaRoute} from "react-icons/fa";
import StepDotsImage from '../assets/images/step_dots.png';
import Step1Image from '../assets/images/upload_illustration.png';
import Step2Image from '../assets/images/magic_illustration.png';
import Step3Image from '../assets/images/solution_illustration.png';

const HowItWorks = () => {
    const StepCard = (props: any) => {
        return (
            <>
                <Card className={'step-card shadow-none'}>
                    <Image src={StepDotsImage} className={'step-dots'}/>
                    <Image src={props.backgroundImage} className={'step-card-img'} />
                    <Card.Body className={'step-card-body'}>
                        <Row>
                            <Col xs={2} sm={2} md={3} lg={2} className={'ps-0 pe-0'}>
                                <h3 className={'step-card-number'}>{props.stepNumber}</h3>
                            </Col>
                            <Col xs={10} sm={10} md={9} lg={10}>
                                <h6 className="text-orange fw-bold">{props.heading}</h6>
                                <p className="text-light">{props.text}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    }

    return (
        <>
            <section className={'bg-dark-gray'}>
                <Container>
                    <Row className={'pb-4 pt-5 how-it-works-header'}>
                        <Col lg={12}>
                            <div className="text-center text-light">
                                <h3>
                                    Start saving money{' '}
                                    with <span className={'text-orange'}>3 easy steps</span>
                                </h3>
                                <p className="text-muted mt-2">
                                    Increase your revenue in no time with the simple steps below.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={'py-5 bg-dark'}>
                <Container>
                    <Row className={''}>

                        <Col md={4} className={'d-flex align-items-stretch py-2 ps-0 pe-sm-0 pe-md-1 pe-lg-2 pe-xl-3'}>

                            <StepCard
                                icon={<AiOutlineCloudUpload/>}
                                backgroundImage={Step1Image}
                                stepNumber={1}
                                heading={'Upload'}
                                text={'Upload your problem using either our API or admin portal.'}
                            />
                        </Col>

                        <Col md={4} className={'d-flex align-items-stretch py-2 px-0 px-md-1 px-lg-2 px-xl-3'}>
                            <StepCard
                                icon={<ImMagicWand/>}
                                backgroundImage={Step2Image}
                                stepNumber={2}
                                heading={'Let us do the magic'}
                                text={'We\'ll optimize for as long as you defined or until you send a stop signal.'}
                            />
                        </Col>

                        <Col md={4} className={'d-flex align-items-stretch py-2 pe-0 ps-sm-0 ps-md-1 ps-lg-2 ps-xl-3'}>
                            <StepCard
                                icon={<FaRoute/>}
                                backgroundImage={Step3Image}
                                stepNumber={3}
                                heading={'Solution'}
                                text={'Retrieve your cost-efficient routes and start your day.'}
                            />
                        </Col>

                    </Row>

                </Container>
            </section>
        </>
    )
}

export default HowItWorks