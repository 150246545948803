import React from 'react'
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {VscCircleFilled} from "react-icons/vsc";
import AdminPanelImage from '../assets/images/admin_panel_illustration.png'

const Admin = () => {

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const ListText = (props: any) => {
        return (
            <p className="text-muted"><VscCircleFilled className={'text-orange'}/> {props.text}</p>
        )
    }

    return (
        <section className={"section pb-3 justify-content-center bg-dark-gray"}>
            <Container>
                <Row className="mb-4 py-5 align-items-center">

                    <Col lg={4} className={'text-sm-center text-lg-start'}>
                        <Image src={AdminPanelImage} className={"img-fluid admin-panel-image justify-content-center mb-5 mb-md-0"}
                               alt="Admin panel illustration"/>
                    </Col>

                    <Col lg={8} className={'ps-5 pt-5 pt-lg-0'}>
                        <h3 className={'fw-normal text-orange'}>You'll love our Admin Panel
                        </h3>
                        <p className="text-muted mt-3">
                            Explore our admin panel which gives you a simple yet extensive overview of everything you
                            need.
                        </p>

                        <div className="mt-4">
                            <ListText text={'Upload problems and start solver jobs.'}/>
                            <ListText text={'View the optimization progress.'}/>
                            <ListText text={'Get an overview of the returned result.'}/>
                            <ListText text={'View your profile, pricing, invoices and much more.'}/>
                        </div>

                        <Button
                            className={'btn-orange mt-3'}
                            onClick={() => {
                                openInNewTab('https://www.app.greengard.no/login')
                            }}
                        >
                            Sign in
                        </Button>

                    </Col>

                </Row>

            </Container>
        </section>
    )
}

export default Admin